/* ==========================================================================
   Fonts
   ========================================================================== */



/* ==========================================================================
   Helper classes
   ========================================================================== */
   .container {
      margin: 0 auto;
      position: relative;
      width: 100%;
      max-width: 1240px;
      padding: 0 15px;
  }
  .container:before, .container:after { content: " "; display: table; }
  .container:after { clear: both; }
  .container { *zoom: 1; }
  .container-fluid { width: 100%; padding: 0 15px; }
  
  .clearfix:before,
  .clearfix:after { content: " "; display: table; }
  
  .clearfix:after { clear: both; }
  
  .visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
  
  /*
   * Extends the .visuallyhidden class to allow the element
   * to be focusable when navigated to via the keyboard:
   * https://www.drupal.org/node/897638
   */
  .visuallyhidden.focusable:active,
  .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
  
  img { max-width: 100%; }
  ul, li { list-style: none; padding: 0; margin: 0; }
  p { margin: 0; padding: 0; }
  h1,h2,h3,h4,h5,h6 { font-weight: 700; margin: 0; line-height: 1em; padding: 0;color: #040404 }
  p { color: #222222; font-size: 18px; line-height: 1.18em; }
  a { text-decoration: none; color: #5C58FC; }
  input[type="text"], input[type="email"] { -webkit-appearance:none; -webkit-border-radius:0; }
  
  /* ==========================================================================
     Responsive helpers
     ========================================================================== */
  
  * { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
  
  /*
   * Grid helpers inspired from Twitter Bootstrap
   */
  .row { margin: 0 -15px; }
  /*.row:before, .row:after { content: " "; display: table; }
  .row:after { clear: both; }
  .row { *zoom: 1; }*/
  .row{
     display: flex;
     flex-wrap:wrap;
  }
  .row>div { padding: 0 15px; }
  
  .col12{width:100%;float:left;}
  .col11{width:91.666666666667%;float:left;}
  .col10{width:83.333333333333%;float:left;}
  .col9{width:75%;float:left;}
  .col8{width:66.666666666667%;float:left;}
  .col7{width:58.333333333333%;float:left;}
  .col6{width:50%;float:left;}
  .col5{width:41.666666666667%;float:left;}
  .col4{width:33.333333333333%;float:left;}
  .col3{width:25%;float:left;}
  .col2{width:16.666666666667%;float:left;}
  .col1{width:8.3333333333333%;float:left;}
  
  .hidden-desktop { display: none; }
  
  .pull-right { float: right; }
  .pull-left { float: left; }
  
  /* ==========================================================================
     Browser Upgrade Prompt
     ========================================================================== */
  
  .browserupgrade { margin: 0.2em 0; background: #ccc; color: #000; padding: 0.2em 0; }
  
  /* ==========================================================================
     Author's custom styles
     ========================================================================== */
  html { }
  body { }
  
  
  /* ==========================================================================
     EXAMPLE Media Queries for Responsive Design.
     Modify as content requires.
     ========================================================================== */
  /*
   * Tablet
   */
  @media only screen and (max-width: 1024px) {
      /* Tablet grid */
      .col-md-12{width:100%;}
      .col-md-11{width:91.666666666667%;}
      .col-md-10{width:83.333333333333%;}
      .col-md-9{width:75%;}
      .col-md-8{width:66.666666666667%;}
      .col-md-7{width:58.333333333333%;}
      .col-md-6{width:50%;}
      .col-md-5{width:41.666666666667%;}
      .col-md-4{width:33.333333333333%;}
      .col-md-3{width:25%;}
      .col-md-2{width:16.666666666667%;}
      .col-md-1{width:8.3333333333333%;}
  
      /* Helpers */
      .hidden-tablet { display: none; }
      .visible-tablet { display: block; }
  }
  
  /*
   * Mobile
   */
  @media only screen and (max-width: 767px) {
      /* Mobile grid */
      .col-sm-12{width:100%;}
      .col-sm-11{width:91.666666666667%;}
      .col-sm-10{width:83.333333333333%;}
      .col-sm-9{width:75%;}
      .col-sm-8{width:66.666666666667%;}
      .col-sm-7{width:58.333333333333%;}
      .col-sm-6{width:50%;}
      .col-sm-5{width:41.666666666667%;}
      .col-sm-4{width:33.333333333333%;}
      .col-sm-3{width:25%;}
      .col-sm-2{width:16.666666666667%;}
      .col-sm-1{width:8.3333333333333%;}
  
      /* Helpers */
      .hidden-mobile { display: none; }
      .visible-mobile { display: block; }
  }  