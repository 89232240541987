/* Header */
.header-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.logo-box a {
    display: block;
}

.logo-box a img {
    width: 100%;
    max-width: 109px;
}
.main-menu {
    display: flex;
    flex-wrap: wrap;
}

.main-menu li a {
    font-size: 17.6px;
    font-weight: 600;
    margin: 0 12px 0;
    color: #666666D9;
}
.main-menu li {
    border-right: 1px solid rgb(0 0 0 / 10%);
    line-height: 18px;
}

a{
   cursor: pointer;
}
.main-menu li:last-child {
    border-right: none;
}
.position-header .main-menu li a {
    color: #fff;
}
header {
    padding: 13px 0;
    border-bottom: 1px solid rgb(34 34 34 / 10%);
}
.position-header {
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
	border-bottom:none;
}
header .container-fluid {
    padding: 0 50px;
}
.toggle-btn button {
    background-color: transparent;
    border: none;
    color: #222;
    font-size: 25px;
}
.position-header .toggle-btn button{
	color: #fff;
}
.cross-btn {
    background-color: transparent;
    border: none;
    color: #666666;
    font-size: 25px;
    display: none;
}

.toggle-btn {
    display: none;
}
/* End Header */