/* Home page */

/* Hero section */
.items-slide {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 170px 0 420px;
    background-image: url("../../Assets/images/hero-banner-bg.jpg");
}
.hero-inner h1 {
    font-size: 72px;
    color: #fff;
    font-weight: 700;
    margin: 0 0 30px;
}
.hero-slider ul.slick-dots {
    bottom: auto;
    right: 0;
    width: 40px;
    top: 50%;
}

.hero-slider .slick-dots li button:before {
    font-size: 15px;
    opacity: 0.5;
    color: #5C58FC !important;
}

.hero-slider .slick-dots li.slick-active button:before {
    opacity: 1;
}
.hero-main {
    position: relative;
}

.hero-slider {
    margin: 0 !important;
}
.hero-inner p {
    font-size: 26px;
    line-height: 38px;
    color: #fff;
    max-width: 620px;
}

.hero-counter {
    background-color: #5C58FF;
    border-radius: 12px;
    padding: 50px 20px;
    max-width: 1100px;
    margin: 0 auto 0;
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 80px;
}
.column-3 {
    width: 25%;
    padding: 0 15px;
}

.hr-count {
    text-align: center;
}

.hr-count h2 {
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    line-height: 66px;
    margin: 0 0 10px;
}

.hr-count p {
    color: #fff;
    font-size: 20px;
    line-height: 31px;
}
/* End Hero section */

/* Plateform section */
.platform-section {
    padding: 170px 0 85px;
    text-align: center;
	position:relative;
}
.platform-section::before {
    content: "";
    width: 4px;
    background-color: #1E73BE;
    height: 135px;
    position: absolute;
    top: -15px;
}
.platform-inner p, .platform-inner h3 {
    font-size: 30px;
    line-height: 42px;
    font-weight: 300;
}
.platform-inner {
    max-width: 1050px;
    margin: 0 auto;
}
/* End Plateform section */

/* Citizen section */
.citizen-section {
    background-color: #4AC193;
}

.citizen-section .row {
    margin: 0;
}

.citizen-section .col6 {
    padding: 0;
}

.citizen-left h6 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    color: #fff;
}

.citizen-left h2 {
    font-size: 45px;
    line-height: 55px;
    color: #fff;
    margin: 10px 0;
}

.citizen-left p {
    font-size: 23px;
    line-height: 38px;
    color: #fff;
    font-weight: 300;
}

.citizen-left {
    padding: 18% 17%;
}

.citizen-right img {
    display: block;
    width: 100%;
}

.citizen-box {
    background-color: #5C58FC;
    width: 100%;
    padding: 15px 40px;
}

.citizen-right {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.citizen-box .row {
    align-items: center;
    height: 100%;
}

.citizen-bx-inner {
    text-align: center;
    padding: 18px 0;
}

.citizen-bx-inner p {
    font-size: 23px;
    line-height: 27px;
    color: #fff;
}

.citizen-bx-inner {
    border-right: 1px solid rgb(255 255 255 / 20%);
}

.citizen-box .col6:last-child .citizen-bx-inner {
    border-right: none;
}
/* End Citizen section */

/* Digital section */
.digital-section {
    padding: 110px 0;
}

.sc-title {
    text-align: center;
}

.sc-title h3 {
    font-size: 36px;
    line-height: 47px;
    font-weight: 400;
    margin: 0 0 15px;
}

.sc-title p {
    font-size: 30px;
    line-height: 49px;
    font-weight: 300;
}

.dil-grid {
    text-align: center;
    width: 33.3%;
    padding: 15px 15px;
    margin: 30px 0;
}

.dil-grid p {
    font-size: 25px;
    line-height: 40px;
    font-weight: 300;
}

.dil-grid img {
    width: 37px;
    margin: 0 auto;
}

.digital-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 0;
}

.dil-grid:nth-child(4), .dil-grid:nth-child(5) {
    width: 50%;
}
/* End Digital section */

/* service section */
.service-section .col4 {
    padding: 0;
}

.srv-inner {
    position: relative;
}

.srv-img img {
    width: 100%;
    display: block;
}

.srv-content {
    position: absolute;
    bottom: 100px;
    text-align: center;
    right: 0;
    left: 0;
    padding: 0 20px;
}

.srv-content h6 {
    color: #fff;
    font-weight: 300;
    font-size: 12.2px;
    text-shadow: 1px 1px rgb(0 0 0 / 50%);
}

.srv-content h3 {
    color: #fff;
    font-size: 30px;
    font-weight: 400;
    line-height: 49px;
    margin: 10px 0 20px;
    text-shadow: 1px 1px rgb(0 0 0 / 50%);
}

.fill-button {
    background-color: #5C58FC;
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.5px;
    padding: 10px 20px;
    line-height: normal;
    display: inline-block;
	border-radius: 5px;
}
/* End service section */

/* morden service section */
.morden-service {
    padding: 110px 0 0;
}

.sc-title hr {
    width: 30px;
    border-color: #5C58FC;
    margin-top: 50px;
}

.morden-inner {
    margin-top: 70px;
}

.morden-inner .row {
    margin: 0;
    align-items: center;
}

.morden-inner .col6 {
    padding: 0;
}

.morden-content {
    padding: 40px 21%;
}
.morden-content h2 {
    font-size: 59px;
    line-height: 77px;
    position: relative;
}

.morden-img img {
    display: block;
    width: 100%;
}
.with-shape::before {
    content: "";
    background-image: url("../../Assets/images/border-shape.png");
    width: 320px;
    height: 10px;
    position: absolute;
    bottom: -10px;
    background-repeat: no-repeat;
    background-size: contain;
}
/* End morden service section */

/* Urban section */
.light-grey-bg {
    background-color: #F6F6F6;
}

.urban-service {
    padding: 90px 0 170px;
}

.urban-title {
    text-align: center;
    max-width: 810px;
    margin: 0 auto 80px;
}

.urban-title img {
    width: 100%;
    max-width: 467px;
}

.urban-title p {
    font-weight: 300;
    font-size: 25px;
    line-height: 40px;
    margin: 40px 0;
}

.urban-img img {
    width: 100%;
    display: block;
}

.urban-content h2 {
    font-size: 31px;
    line-height: 51px;
    font-weight: 300;
}

.urban-content p {
    font-size: 14px;
    line-height: 24px;
    margin: 12px 0;
}
.urban-row .row {
    align-items: center;
}
.urb-rw {
    display: flex;
    flex-wrap: wrap;
}
.urban-row:first-child .urban-img {
    margin-left: -60px;
}

.urban-row:last-child .urban-img {
    margin-right: -60px;
}
.urb-text {
    width: calc(100% - 37px);
    padding: 0 0 0 15px;
}

.urb-icon {
    width: 37px;
}

.urb-text h3 {
    font-size: 24px;
    line-height: 38px;
    font-weight: 300;
}
.urban-row {
    margin: 0 0 30px;
}

.urban-img {
    padding-right: 10px;
}
/* End Urban section */

/* Download app section */
.download-app {
    padding: 90px 0;
}

.download-inner {
    text-align: center;
}

.download-inner h3 {
    font-size: 33px;
    line-height: 53px;
    font-weight: 300;
    margin: 0 0 15px;
}

.buttons-grp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.buttons-grp a {
    margin: 0 5px;
    padding: 13px 25px;
    border-radius: 5px;
}

.dark-grey-btn {
    background-color: #4C4C4C;
}
/* End Download app section */

/* Opinions section */
.green-bg {
    background-color: #4AC193;
}

.opinions-section {
    padding: 140px 0;
}

.opinions-title {
    text-align: center;
    margin: 0 0 40px;
}

.opinions-title h3 {
    color: #fff;
    font-weight: 300;
    font-size: 39px;
    line-height: 63px;
}

.opinions-title p {
    color: #fff;
}

.opinions-bx {
    background-color: #fff;
    border-radius: 20px;
    padding: 25px;
    margin: 15px;
    width: 34%;
}

.opinions-bx img {
    width: 82px;
}

.opinions-bx p {
    font-size: 16px;
    line-height: 22px;
    font-style: italic;
}

.opinions-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.opinions-bx:nth-child(3), .opinions-bx:nth-child(4) {
    width: 41%;
}

.opinions-bx:last-child {
    width: 45%;
}
/* End Opinions section */

/* CTC section */
.ctc-section {
    background-color: #5C58FC;
    padding: 65px 0;
}

.ctc-inner {
    text-align: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.ctc-inner p {
    color: #fff;
    font-size: 25px;
    line-height: 33px;
    font-weight: 300;
    margin: 0 0 40px;
}

.ctc-inner h3 {
    color: #fff;
    font-size: 43px;
    line-height: 56px;
}
.ctc-inner h4 {
    font-size: 34px;
    line-height: 45px;
    color: #fff;
    margin: 0 0 40px;
}

.ctc-inner a {
    background-color: #fff;
    color: #666666;
    font-size: 18px;
    padding: 16px 36px;
    display: inline-block;
    border-radius: 50px;
    vertical-align: middle;
}
.ctc-inner a img {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 8px;
    position: relative;
    top: -2px;
}
/* End CTC section */

/*News section*/
.news-section {
    padding: 80px 0;
}

.news-title {
    text-align: center;
    margin: 0 0 50px;
}

.news-title h2 {
    position: relative;
    font-size: 38px;
    line-height: 50px;
}

.news-title h2::before {
    content: "";
    background-color: rgb(4 4 4 / 10%);
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 52%;
    transform: translateY(-50%);
}

.news-title h2 span {
    background-color: #fff;
    padding: 0 10px;
    position: relative;
    z-index: 1;
}
/*end of news*/

/* End Home page */

@media only screen and (max-width:1440px){
/* Home page */	
.hero-inner h1 {
    font-size: 58px;
}

.hero-inner p {
    font-size: 22px;
    line-height: 32px;
    max-width: 520px;
}

.hr-count h2 {
    font-size: 42px;
    line-height: 58px;
}

.hr-count p {
    font-size: 18px;
    line-height: 29px;
}

.platform-inner p, .platform-inner h3 {
    font-size: 24px;
    line-height: 36px;
}

.citizen-left h6 {
    font-size: 16px;
    line-height: 20px;
}

.citizen-left h2 {
    font-size: 40px;
    line-height: 50px;
}

.citizen-left p {
    font-size: 20px;
    line-height: 33px;
}

.sc-title h3 {
    font-size: 30px;
    line-height: 41px;
}

.sc-title p {
    font-size: 24px;
    line-height: 38px;
}

.dil-grid p {
    font-size: 22px;
    line-height: 36px;
}

.srv-content h3 {
    font-size: 24px;
    line-height: 30px;
}

.srv-content {
    bottom: 60px;
}

.morden-content h2 {
    font-size: 50px;
    line-height: 68px;
}

.with-shape::before {
    width: 270px;
}

.urban-title p {
    font-size: 22px;
    line-height: 36px;
}

.urban-title img {
    max-width: 367px;
}

.urban-row:first-child .urban-img {
    margin: 0;
}

.urban-row:last-child .urban-img {
    margin: 0;
}

.ctc-inner p {
    font-size: 22px;
    line-height: 30px;
}

.ctc-inner h3 {
    font-size: 36px;
    line-height: 50px;
}

.ctc-inner h4 {
    font-size: 30px;
    line-height: 41px;
}

.ctc-inner a {
    font-size: 16px;
}	
/* End Home page */		
}
@media only screen and (max-width:991px){
/* Home page */	
.items-slide {
    padding: 170px 0 280px;
}

.hero-counter {
    padding: 30px 15px;
    bottom: 50px;
}

.items-slide {
    background-position: right -90px center;
}	
.platform-section {
    padding: 150px 30px 70px;
}

.citizen-section .col6 {
    width: 100%;
}

.citizen-left {
    padding: 15% 16%;
}	
.citizen-box .col6 {
    width: 50%;
}

.srv-img img {
    object-fit: cover;
    height: 550px;
}

.morden-inner .col6 {
    width: 100%;
}

.morden-inner .row {flex-direction: column-reverse;}

.morden-content {
    padding: 60px 15%;
}

.urban-title img {
    max-width: 311px;
}

.urban-row .col6 {
    width: 100%;
}

.urban-content {
    padding: 30px 11% 0;
}

.urb-icon {
    width: 33px;
}

.urb-text {
    width: calc(100% - 33px);
}

.urban-row {
    margin: 0 0 0;
}

.urban-row:last-child .urban-img {
    padding-left: 11%;
    margin-top: 30px;
}

.opinions-bx {
    width: 100% !important;
    max-width: 450px;
}

.opinions-section {
    padding: 100px 0;
}

.opinions-title h3 {
    margin: 0 0 10px;
}

/* End Home page */	

}
@media only screen and (max-width:767px){
/* Home page */
.hero-counter {
    display: none;
}

.items-slide {
    padding: 150px 0 50px;
    background-position: right 0px center;
}

.hero-inner p {
    max-width: 100%;
    font-size: 18px;
    line-height: 24px;
}

.hero-inner h1 {
    font-size: 50px;
    line-height: 50px;
    margin: 0 0 15px;
}
.citizen-left {
    padding: 50px 30px;
}

.citizen-left h2 {
    font-size: 36px;
    line-height: 46px;
}

.citizen-left p {
    font-size: 18px;
    line-height: 29px;
}

.citizen-box .col6 {
    width: 100%;
}

.citizen-bx-inner {
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    border-right: none;
    padding: 20px 0;
}

.citizen-box .col6:last-child .citizen-bx-inner {
    border-bottom: none;
}

.citizen-box {
    padding: 15px 20px;
}

.citizen-bx-inner p {
    font-size: 18px;
    line-height: 22px;
}

.digital-section {
    padding: 60px 0;
}

.sc-title h3 {
    font-size: 28px;
    line-height: 37px;
}

.sc-title p {
    font-size: 20px;
    line-height: 32px;
}

.dil-grid {
    width: 100% !important;
    margin: 20px 0;
}

.dil-grid p {
    font-size: 20px;
    line-height: 32px;
}

.digital-grid {
    margin-top: 20px;
}

.service-section .col4 {
    width: 100%;
}

.morden-service {
    padding: 70px 0 0;
}

.morden-content {
    padding: 60px 30px;
}

.morden-content h2 {
    font-size: 45px;
    line-height: 63px;
}

.urban-title img {
    max-width: 186px;
}

.urban-service {
    padding: 70px 0 100px;
}

.urban-title p {
    font-size: 19px;
    line-height: 30px;
}

.urban-content {
    padding: 30px 0 0;
}

.urban-content h2 {
    font-size: 25px;
    line-height: 39px;
}

.urb-text h3 {
    font-size: 19px;
    line-height: 30px;
}

.urban-row:last-child .urban-img {
    padding: 0;
}

.download-inner h3 {
    font-size: 25px;
    line-height: 39px;
}

.buttons-grp a {
    font-size: 15px;
}

.download-app {
    padding: 70px 0;
}

.opinions-section {
    padding: 40px 0 70px;
}

.opinions-title h3 {
    font-size: 30px;
    line-height: 49px;
}
.opinions-title {
    margin: 0 0 10px;
}

.opinions-bx {
    margin-left: 0;
    margin-right: 0;
}

.ctc-inner p {
    font-size: 20px;
    line-height: 24px;
}

.ctc-inner h3 {
    font-size: 28px;
    line-height: 38px;
}

.ctc-inner h4 {
    font-size: 28px;
    line-height: 39px;
}

.fill-button {
    font-size: 15px;
}	
/* End Home page */		

}