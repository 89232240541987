/* News section */
.news-section {
    padding: 80px 0;
}

.news-title {
    text-align: center;
    margin: 0 0 50px;
}

.news-title h2 {
    position: relative;
    font-size: 38px;
    line-height: 50px;
}

.news-title h2::before {
    content: "";
    background-color: rgb(4 4 4 / 10%);
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 52%;
    transform: translateY(-50%);
}

.news-title h2 span {
    background-color: #fff;
    padding: 0 10px;
    position: relative;
    z-index: 1;
}

/* End News section */


/* Blog page */
.blog-banner img {
    width: 100%;
    display: block;
}
.blog-main {
    padding: 0 0 50px;
}
.blog-left h2 {
    font-size: 40px;
    line-height: 52px;
    margin: 0 0 20px;
}

.blog-left p {
    font-size: 18px;
    line-height: 31px;
}

.blog-right {
    background-color: #F1F1F1;
    padding: 90px;
    border-top: 10px solid #5C58FC;
    margin-top: -140px;
}

.blog-right h4 {
    font-size: 25px;
    line-height: 40px;
    margin: 0 0 10px;
}

.blog-right p {
    line-height: 29px;
}

.blog-top .row {
    align-items: center;
}

.blog-left {
    max-width: 500px;
    width: 100%;
}

.blog-news {
    margin: 70px 0 0;
}

.blog-news .news-img img {
    height: auto;
}

.blog-news .news-bx {
    background-color: transparent;
    box-shadow: none;
}

.blog-news .news-bx .news-content {
    background-color: rgb(255 255 255 / 95%);
    margin: -50px 20px 0;
    display: inline-block;
    position: relative;
}
/* End Blog page */

/* Single Blog page */
.single-blog {
    padding: 30px 0 90px;
}

.sg-blg-image img {
    width: 100%;
    display: block;
}

.sg-blg-image {
    position: relative;
    margin: 0 0 22px;
}
.blog-left-content {
    padding-right: 20px;
}
.blg-dt {
    position: absolute;
    top: 30px;
    width: 44px;
    height: 44px;
    text-align: center;
    background-color: #fff;
    border: 2px solid #5C58FC;
    left: -6px;
    color: #5C58FC;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    padding: 4px 4px;
}

.blg-dt small {
    display: block;
    font-size: 12px;
}

.blog-content h6 {
    color: #2554BB;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.blog-content h2 {
    font-size: 27px;
    line-height: 35px;
    position: relative;
    padding: 0 0 15px;
    margin: 0 0 15px;
}

.blog-content h2::before {
    content: "";
    width: 30px;
    height: 3px;
    background-color: rgb(0 0 0 / 10%);
    position: absolute;
    bottom: 0;
}

.post-on {
    font-size: 12px;
    line-height: 13px;
    text-transform: uppercase;
    color: #222222;
    font-weight: 300;
    margin: 0 0 15px;
}

.post-on span {
    color: #2554BB;
}

.blog-content p {
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    margin: 0 0 20px;
}

.social-links ul {display: flex;justify-content: center;}

.social-links ul a {
    width: 32px;
    height: 32px;
    border: 2px solid #C0C0C0;
    color: #C0C0C0;
    display: inline-block;
    text-align: center;
    line-height: 28px;
    border-radius: 50px;
    margin: 0 2px;
}

.social-links {
    padding: 20px 0 35px;
    position: relative;
}

.social-links::before {
    content: "";
    width: 32px;
    height: 3px;
    background-color: rgb(0 0 0 / 10%);
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
}

.pagination-links {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #ECECEC;
    border-bottom: 1px solid #ECECEC;
}

.pagination-links a {
    width: 50%;
    padding: 18px 5px;
    color: #2554BB;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    border-right: 1px solid #ECECEC;
    text-align: left;
}
.pagination-links a i {
    margin: 0 5px;
}
.pagination-links a:last-child {
    border-right: none;
    text-align: right;
}

.widget-rep h3 {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    letter-spacing: 0.8px;
    padding: 0 0 15px;
    margin: 0 0 15px;
    position: relative;
}

.widget-rep h3::before {
    content: "";
    width: 30px;
    height: 3px;
    background-color: rgb(0 0 0 / 10%);
    position: absolute;
    bottom: 0;
}

.widget-rep p {
    font-size: 16px;
    line-height: 25px;
}

.widget-rep {
    margin: 0 0 25px;
}

.widget-rep ul li a {
    color: #2554BB;
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
}

.widget-rep ul li span {
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    display: inline-block;
}

.widget-rep ul li {
    border-bottom: 1px solid #ECECEC;
    padding: 8px 0;
}

.widget-rep ul li:last-child {
    border-bottom: none;
}
/* End Single Blog page */

@media only screen and (max-width:991px){
/* Blog page */
.blog-top .col6 {
    width: 100%;
}

.blog-right {
    margin-top: 0;
    padding: 70px;
}

.blog-left {
    max-width: 100%;
    padding: 50px 0;
}
/* End Blog page */
}
@media only screen and (max-width:767px){
.news-title h2 span {
    padding: 0;
}

.news-title h2 {
    font-size: 29px;
    line-height: 37px;
    padding: 15px 0;
}

.news-title h2::before {
    top: 0;
    transform: unset;
}

.news-title h2::after {
    content: "";
    background-color: rgb(4 4 4 / 10%);
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.news-section {
    padding: 60px 0;
}

.news-title {
    margin: 0 0 30px;
}


/* Blog page */		
.blog-left h2 {
    font-size: 32px;
    line-height: 43px;
    margin: 0 0 10px;
}

.blog-right {
    padding: 30px;
}

.blog-right h4 {
    font-size: 20px;
    line-height: 32px;
}

.blog-right p {
    font-size: 16px;
    line-height: 27px;
}

.blog-banner img {
    object-fit: cover;
    height: 300px;
}
.blog-left {
    padding: 40px 0;
}

.blog-news {
    margin-top: 50px;
}
/* End Blog page */		
.single-blog .col9 {
    width: 100%;
}

.single-blog .col3 {
    width: 100%;
}

.blog-sidebar {
    margin-top: 50px;
}

.blog-content h2 {
    font-size: 24px;
    line-height: 31px;
}
.blog-left-content {
    padding: 0;
}
.pagination-links a {
    width: 100%;
    text-align: center !important;
    border-bottom: 1px solid #ECECEC;
    border-right: none;
}

.pagination-links a:last-child {
    border-bottom: none;
}
/* Single Blog page */
/* End Single Blog page */
}