body{
	margin:0px;
	padding:0px;
	font-family: 'Open Sans', sans-serif;
}
.primary-color {
    color: #5C58FC;
}

/* Footer */
footer {
    background-color: #5B5B5B;
    text-align: center;
    padding: 16px 0;
}

footer p {
    color: rgb(255 255 255 / 50%);
    font-size: 14px;
    font-weight: 300;
}
/* End Footer */


@media only screen and (max-width:991px){
/* Header */	
.navigation {
    display: none;
}

.toggle-btn {
    display: block;
}

header .container-fluid {
    padding: 0 15px;
}
.main-menu li {
    border-right: none;
}
.navigation.show-menu {
    display: block;
    position: fixed;
    background-color: #fff;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    padding-left: 25%;
	z-index: 999;
}

.show-menu .cross-btn {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 20px;
}

ul.main-menu {
    background-color: #F9F9F9;
    padding: 130px 20px 20px 20px;
    height: 100%;
    display: inline-block;
    width: 100%;
}

ul.main-menu li {
    width: 100%;
}

.main-menu li a {
    color: #222222 !important;
    margin: 7px 0;
    display: inline-block;
}
/* End Header */	

}
@media only screen and (max-width:767px){
	
}