
.news-bx {
    box-shadow: 0px 2px 6px 3px rgb(0 0 0 / 16%);
    display: inline-block;
    background-color: #fff;
}

.news-inner .col4 {
    margin: 15px 0;
}

.news-img {
    position: relative;
}

.news-img img {
    width: 100%;
    display: block;
    object-fit: cover;
    height: 180px;
}

.date-ns {
    position: absolute;
    top: 20px;
    width: 44px;
    height: 44px;
    background-color: #5C58FC;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 16px;
    line-height: 13.6px;
    padding: 8px 2px;
    left: 10px;
}

.date-ns small {
    width: 100%;
    display: inline-block;
    font-size: 11.2px;
    line-height: 9.52px;
}

.news-content {
    text-align: center;
    padding: 15px 20px 25px;
}

.news-content h3 {
    font-size: 16px;
    line-height: 21px;
    position: relative;
    padding: 0 0 8px;
    margin: 0 0 8px;
}

.news-content p {
    font-size: 14.4px;
    line-height: 23px;
}

.news-content h3::before {
    content: "";
    width: 30px;
    height: 2px;
    background-color: rgb(0 0 0 / 10%);
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
}

.show-more {
    text-align: right;
    margin-top: 30px;
}


@media only screen and (max-width:1440px) {
    .news-inner .col4 {
        width: 100%;
    }
}
